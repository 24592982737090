import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// Component Imports
import { Section } from '../components/page-elements';
import SEO from '../components/seo';
import Container from '../components/container';
import CenteredHeaderBlock from '../components/centeredHeaderBlock';
import BottomCut from '../components/bottomCut';
import AnimatedStripe from '../components/animatedStripe';
import Button from '../components/buttons';
import FormInput2 from '../components/formInput2';
import Spacer from '../components/spacer';
import CTABlock from '../components/ctaBlock';

// Asset Imports
import Stripes07SVG from '../images/stripes/stripes07.inline.svg';
import { TechnicalSmall } from '../theme/typography';

// Styled Components
const PageStripes = styled(AnimatedStripe)`
  position: absolute;
  top: 80%;
  left: -40%;
  width: 100%;
  height: 300%;
  z-index: 1;
  pointer-events: none;

  @media (min-width: 64em) {
    top: 60%;
    left: -25%;
    height: 175%;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 800px;
  position: relative;
  margin: 0 auto;

  background: var(--blue6);
  border-radius: 15px;
  box-shadow: var(--box-shadow-xl);
  padding: 48px 32px;
  z-index: 2;

  margin-top: -176px;

  @media (min-width: 1024px) {
    margin-top: -224px;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;
const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;

  @media (min-width: 64em) {
    flex-wrap: unset;
  }
`;

const FormNote = styled(TechnicalSmall)`
  color: var(--blue4);
  text-align: center;
  > span {
    font-weight: var(--font-weight-semibold);
  }
`;

const FormButtonStyles = css`
  width: 100%;

  @media (min-width: 37.5em) {
    width: unset;
  }
`;

const SendMessageButton = styled(Button)`
  ${FormButtonStyles};
  margin-bottom: ${({ theme }) => theme.spacing.md};
  align-self: flex-end;
  &:disabled {
    background-color: ${({ theme }) => theme.color.blue4};
    opacity: 0.6;
    cursor: unset;

    &:hover {
      transform: none;
      box-shadow: none;
    }
  }
`;

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, `C'mon your name is longer than that!`)
    .required(`Required`),
  email: Yup.string().email(`Must be a valid email.`).required(`Required`),
  phone: Yup.string().matches(
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/,
    `Must be a valid phone number.`,
  ),
  message: Yup.string()
    .min(3, `Too short!`)
    .required(`Let us know how we can help!`),
});

const ContactPage = ({ data }) => {
  const formEl = useRef(null);

  return (
    <>
      <SEO title='Contact' />
      <Section dark cuts='bottomCut'>
        <Container>
          <CenteredHeaderBlock data={data.sanityContactPage.headerBlock} />
        </Container>
        <BottomCut light />
        <PageStripes direction='left' midPage triggerOnce>
          <Stripes07SVG />
        </PageStripes>
      </Section>
      <Section light cuts='topAndBottomCut'>
        <Container>
          <FormContainer>
            <Formik
              initialValues={{
                'form-name': `Contact Form`,
                'bot-field': ``,
                name: ``,
                email: ``,
                phone: ``,
                message: ``,
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                formEl.current.submit();
              }}
            >
              {({
                handleChange,
                handleBlur,
                values,
                errors,
                touched,
                isValid,
              }) => (
                <StyledForm
                  name='Contact Form'
                  method='POST'
                  data-netlify='true'
                  data-netlify-honeypot='bot-field'
                  ref={formEl}
                  action='/thankYou'
                >
                  {/* The `form-name` hidden field is required to support form
                submissions in Netlify */}
                  <input type='hidden' name='form-name' value='Contact Form' />
                  <p hidden>
                    Don’t fill this out if you're a human:{' '}
                    <input name='bot-field' />
                  </p>
                  <FormInput2
                    name='name'
                    label='Name'
                    placeholder='Your name'
                    required
                    value={values.name}
                    onChange={handleChange('name')}
                    onBlur={handleBlur('name')}
                    errorCase={!!errors.name && touched.name}
                    errorMsg={errors.name}
                  />
                  <Spacer size={24} />
                  <FormRow>
                    <FormInput2
                      name='email'
                      label='Email'
                      placeholder='Email address'
                      required
                      value={values.email}
                      onChange={handleChange('email')}
                      onBlur={handleBlur('email')}
                      errorCase={!!errors.email && touched.email}
                      errorMsg={errors.email}
                    />
                    <FormInput2
                      name='phone'
                      label='Phone'
                      placeholder='Phone number'
                      value={values.phone}
                      onChange={handleChange('phone')}
                      onBlur={handleBlur('phone')}
                      errorCase={!!errors.phone && touched.phone}
                      errorMsg={errors.phone}
                    />
                  </FormRow>
                  <Spacer size={24} />

                  <FormInput2
                    name='message'
                    label='Message'
                    placeholder='How can we help?'
                    multiline
                    required
                    value={values.message}
                    onChange={handleChange('message')}
                    onBlur={handleBlur('message')}
                    errorCase={!!errors.message && touched.message}
                    errorMsg={errors.message}
                  />
                  <Spacer size={40} />

                  <SendMessageButton
                    type='submit'
                    disabled={!isValid}
                    label='Send Message'
                    arrow
                  />
                  <FormNote>
                    The Rubberduck team will get back to you within{' '}
                    <span>24 hours.</span>
                  </FormNote>
                </StyledForm>
              )}
            </Formik>
          </FormContainer>
        </Container>
      </Section>
      <Section light cuts='bottomCut'>
        <Container>
          <CTABlock
            data={{
              type: 'primary',
              darkBG: false,
              title: 'Urgent enquiry or just prefer a phone call?',
              preHeader: 'Always up for a chat',
              body: `We're always available to talk, just drop us a line or give us a buzz at the details here.`,
              primaryCTA: false,
              secondaryCTA: false,
              subPoint1: {
                content: 'employee',
                employee: data.allSanityEmployee.nodes.find(
                  (employee) => employee.email == 'ben@rubberduck.ie',
                ),
              },
              subPoint2: {
                content: 'employee',
                employee: data.allSanityEmployee.nodes.find(
                  (employee) => employee.email == 'helen@rubberduck.ie',
                ),
              },
            }}
          />
        </Container>
        <BottomCut dark />
      </Section>
    </>
  );
};

// GraphQL Query
export const contactPageQuery = graphql`
  {
    sanityContactPage {
      headerBlock {
        ...SanityCenteredHeaderBlockFragment
      }
    }
    allSanityEmployee {
      nodes {
        firstName
        lastName
        email
        phoneNumber
        role
        headshot {
          ...SanityImageFragment
        }
      }
    }
  }
`;

export default ContactPage;
